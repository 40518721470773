<template>
  <div id="google_btn"></div>
</template>

<script>
import config from "../../config.js"

export default {
  name: "ButtonGoogle",
  components: {},
  data() {
    return {};
  },
  async mounted() {
    // Create a script to load the Google API
    const googleScript = document.createElement("script");
    googleScript.src = "https://apis.google.com/js/platform.js";
    googleScript.async = true;
    googleScript.onload = () => {
      this.initializeGoogleButton();
    };

    // Add the script to the DOM
    document.head.appendChild(googleScript);
  },
  methods: {
    async initializeGoogleButton() {
      if (
        typeof window.google !== "undefined" &&
        window.google.accounts &&
        window.google.accounts.id
      ) {
        window.google.accounts.id.initialize({
          client_id: "5391341510-jpnimbbjsqe2l718smmtmgs86h0jcumk.apps.googleusercontent.com",
          callback: this.handleLogin,
          allowed_parent_origin: [`${config.FRONT_URL}`],
          ux_mode: "popup",
        });
        const parent = document.getElementById("google_btn");
        window.google.accounts.id.renderButton(parent, {
          locale: "en",
          type: "icon",
          shape: "rectangular",
          theme: "outline",
          text: "signin_with",
          size: "large",
          width: 700,
          logo_alignment: "center",
          click_listener: this.handleClickHandler,
        });
        window.google.accounts.id.prompt();
      }
    },
    handleLogin(response) {
      if (response?.credential) {
        this.$emit('on_google_login', response);
      }
    },
    handleClickHandler() {
      console.log("Sign in with Google button clicked...");
    },
  },
};
</script>

<style scoped>
#google_btn {
  display: flex;
  justify-content: center;
}

::v-deep .S9gUrf-YoZ4jf {
  top: -8.2rem !important;
  width: 505px !important;
}

::v-deep iframe {
  display: none !important;
  position: relative !important;
  top: -5rem !important;
}

::v-deep .S9gUrf-YoZ4jf div .nsm7Bb-HzV7m-LgbsSe.Bz112c-LgbsSe {
  width: 100%;
  height: 65px;
  background-color: transparent;
  border: unset;
}
::v-deep .S9gUrf-YoZ4jf div .nsm7Bb-HzV7m-LgbsSe.Bz112c-LgbsSe:hover {
  background-color: unset !important;
}

@media screen and (min-width: 463px) {
  ::v-deep .S9gUrf-YoZ4jf div .nsm7Bb-HzV7m-LgbsSe.Bz112c-LgbsSe {
    max-width: 505px !important;
  }
}
</style>