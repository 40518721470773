<script>
import GoogleIcon from '@/components/Login/GoogleIcon.vue';

export default {
    name: 'CustomGoogleSignInButton',
    components: {
        GoogleIcon,
    },
    props: {
        marginBottom: {
            type: String,
            default: "2rem",
        },
    },
    computed: {
        marginStyle() {
            return { 'margin-bottom': this.marginBottom };
        },
    }
}
</script>

<template>
    <!-- Customizable google button -->
    <div class="google-button-container" :style="marginStyle">
        <div class="google-button-text">
            <p>Sign in with Google</p>
        </div>

        <div class="google-button-logo">
            <GoogleIcon />
        </div>
    </div>
</template>


<style scoped>
.google-button-container {
    height: 74px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 2rem;
    box-shadow: 0 10px 46px #E1EAFC;
}

.google-button-text p {
    font-family: Helvetica;
    font-size: 16px;
    margin: unset;
}
</style>