import { loginGoogleUser } from '../services/session';
import { getInitials } from '../utils/textUtilities';
import { reinitializeSocketWithToken } from "../services/socket_background";
import router from '../router';

export function useAuthWithGoogle() {
  let user = null;

  async function loginWithGoogle(data) {
    try {
      const res_data = await loginGoogleUser(data);

      if (res_data.error === false) {
        const save_user = {
          loggedIn: true,
          email: res_data.user.email,
          name: res_data.user.name,
          id_group: res_data.user.id_group,
          initials: getInitials(res_data.user.name),
          image: res_data.user.image,
          permissions: res_data.permissions,
        };

        localStorage.setItem('token', res_data.token);
        reinitializeSocketWithToken(res_data.token);
        localStorage.setItem('refresh_token', res_data.refresh_token);
        localStorage.setItem('user_data', JSON.stringify(save_user));
        user = save_user; // Actualiza user con los datos

        if (res_data?.user?.show_welcome == true) {
          router.push('/editproject?welcome=true');
        } else {
          router.push('/');
        }
      } else {
        throw new Error(res_data.message || 'An error has occurred.');
      }
    } catch (error) {
      throw new Error(error.message || 'An error has occurred.');
    }
  }

  return {
    getUser: () => user, // Devuelve el estado user
    loginWithGoogle,
  };
}
