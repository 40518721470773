export function clearText(text) {
  return text
    .replaceAll("e%3Fg%3F ", "e.g.")
    .replaceAll("N%3FA", "N.F")
    .replaceAll("%3F", ".")
    .replaceAll("e.g%3F", "e.g.")
    .replaceAll("%2", " / ")
    .replaceAll("e.Fg%3F", "e.g.")
    .replaceAll("?", " ")
    .replaceAll("g?", " ")
    .replaceAll("%2", " ")
    .replaceAll("-", "");
}

export function infoDemoProject() {
  return "This demo project, presents beneficiaries in employment, with the types of employment status in the 1st drop down, showcased across the programme’s funds or charities in the 2nd drop down and matched with certain demographics in the 3rd drop down.";
}

export function getInitials(username) {
  let initials = username.match(/\b\w/g) || [];
  return ((initials.shift() || "") + (initials.pop() || "")).toUpperCase();
}
