<script>
export default {
    name: 'LineDividerWithText',
    components: {},
    props: {
        dividerText: {
            type: String,
            default: 'or',
        }
    },
}
</script>

<template>
    <div class="or-container">
        <div class="line"></div>
        <p class="or-text">{{ dividerText }}</p>
        <div class="line"></div>
    </div>
</template>

<style scoped>
.or-container {
    display: flex;
}

.line {
    border-bottom: 1px solid #DCE2F0;
    flex-grow: 1;
    height: 1px;
}

.or-text {
    font-family: Helvetica;
    font-size: 13px;
    background-color: #f5f7fc;
    position: relative;
    top: -1.3rem;
    padding: 0 1rem;
}

@media screen and (min-width: 576px) {
    .or-text {
        font-size: 16x;
    }
}
</style>